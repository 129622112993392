const wikiListGroup = [
  [{ name: "Boas vindas" }],
  [
    { name: "Revisão Sistemática e Meta-Análise" },
    { name: "O que é Revisão Sistemática?" },
    { name: "O que é Meta-Análise?" },
    { name: "Revisões clínicas e pré-clínicas" },
    { name: "Por que realizar RSs pré-clínicas?" },
  ],
  [{ name: "Revisões sistemáticas e 3Rs" }, { name: "" }],
  [
    { name: "Antes de Começar" },
    { name: "Ela é necessária?" },
    { name: "Isso já foi feito antes?" },
    { name: "Existe uma já em andamento?" },
    { name: "Construa sua equipe de revisão sistemática" },
  ],
  [
    { name: "Pergunta de Pesquisa" },
    { name: "Partes Interessadas" },
    { name: "Exemplos para revisões pré-clínicas" },
  ],
  [
    { name: "Protocolo" },
    { name: "Modelos de protocolos" },
    { name: "Registre seu protocolo" },
    { name: "Seu protocolo e 3Rs" },
  ],
  [
    { name: "Busca Sistemática" },
    { name: "Selecionando Bases de Dados" },
    { name: "Desenvolvimento de estratégia de busca" },
    { name: "Executando pesquisas e combinando resultados" },
    { name: "Deduplicação" },
    { name: "Atualize sua pesquisa e ferramentas" },
    { name: "Localizar e recuperar textos integrais" },
  ],
  [
    { name: "Seleção de estudos" },
    { name: "Critérios de Inclusão e Exclusão" },
    { name: "Aplique seus critérios" },
    { name: "Ferramentas para triagem" },
  ],
  [
    { name: "Extração de dados" },
    { name: "Características do estudo" },
    { name: "Dados Quantitativos" },
    { name: "Software de extração de dados" },
  ],
  [
    { name: "Avaliação de qualidade" },
    { name: "Relatórios" },
    { name: "Risco de viés" },
    { name: "Avaliação RoB para informar a análise" },
  ],
  [
    { name: "Meta-análise" },
    { name: "Calculando tamanho do efeito" },
    { name: "Combinar tamanhos do efeito" },
    { name: "Investigar a Heterogeneidade" },
    { name: "Documentando Enviesamento" },
    { name: "Interpretando os Resultados" },
  ],
  [{ name: "Ferramentas para Revisão Sistemática" }, { name: "" }],
  [{ name: "Publicação" }, { name: "Produzindo um bom relatório" }],
  [{ name: "Recursos e links" }, { name: "" }],
  [
    { name: "Sobre" },
    { name: "Como citar a ferramenta?" },
    // { name: "Autores do recurso em inglês" },
    { name: "Equipe de tradução" },
    { name: "Demais apoiadores" },
    { name: "Contato" },
  ],
]

export default wikiListGroup
