import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import WikiTabs from "../components/sections/wiki/wiki_tabs"
import Seo from "../components/seo"

const WikiPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="Wiki"
      lang="pt"
      description="Este é a Wiki CAMARADES para revisão sistemática e meta-análise pré-clínica. Encontre aqui  informações, links, artigos e outras ferramentas úteis para te guiar ao longo de uma revisão sistemática"
    />
    <GradientJumbo subtitle="Wiki" title="Um manual para revisão sistemática e meta-análise pré-clinica" />
    <WikiTabs />
    <Contact language="pt" />
  </Layout>
)

export default WikiPage
