import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row, Tab } from "react-bootstrap"
import wikiListGroup from "../../../data/wikiListGroup"
import { getPTCitation } from "../../../utils"
import ListGroupWikiItem from "../../listgroup/ListGroupWikiItem"

export default function WikiTabs() {
  return (
    <section className="container" id="wiki">
      <Tab.Container defaultActiveKey="#00">
        <Row>
          <Col sm={4}>
            <ListGroupWikiItem array={wikiListGroup} />
          </Col>
          <Col sm={1}></Col>
          <Col sm={6}>
            <Tab.Content className="mt-3 mt-md-0">
              <Tab.Pane eventKey="#00">
                <h3 className="fw-bold">Boas vindas!</h3>
                <p>Olá revisores e revisoras! </p>
                <p>
                  Encontre aqui informações, links, artigos e outras ferramentas
                  úteis para te guiar ao longo de uma revisão sistemática.
                </p>
                <p>
                  Navegue pelas seções e saiba mais sobre o que é uma revisão
                  sistemática pré-clínica, quais são os passos e como
                  completá-los.
                </p>
                <p>
                  Use a lista de conteúdos no menu a esquerda da tela para
                  navegar pelos passos de uma revisão sistemática.
                </p>
                <p>
                  Se você tiver questões a respeito do conteúdo ou quiser fazer
                  alguma outra pergunta sobre esse tipo de revisão, envie um
                  Email pra nós CAMARADES BR (em português) ou para CAMARADES
                  Berlin (em inglês).
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#10">
                <h3 className="fw-bold">1 Revisão Sistemática Pré-clínica</h3>
                <p>
                  Você está pensando em iniciar uma Revisão Sistemática
                  Pré-clínica (RS), e agora?
                </p>
                <p>
                  Continue rolando a página para saber mais sobre o que é uma
                  revisão sistemática pré-clínica, quais são as etapas
                  envolvidas e como conduzi-las.
                </p>
                <p>
                  Use o índice no lado esquerdo, em dispositivos grandes, ou
                  acima da tela em dispositivos menores, para navegar pelas
                  etapas de uma revisão sistemática.
                </p>

                <h3>1.1 O que é uma revisão sistemática?</h3>
                <p>
                  Uma revisão sistemática (RS) é uma revisão de literatura que
                  envolve localizar, avaliar e sintetizar evidências de estudos
                  científicos sistematicamente para responder a uma pergunta de
                  pesquisa definida com base em critérios pré-especificados.
                </p>
                <p>
                  Os métodos de uma revisão sistemática (e meta-análise) devem
                  ser transparentes e reproduzíveis, além de mapeados e
                  relatados para que a revisão possa ser repetida.
                </p>

                <h3>1.2 O que é uma meta-análise?</h3>
                <p>
                  Um método de combinação de resultados quantitativos de estudos
                  individuais identificados por meio de revisão sistemática em
                  uma análise estatística geral.
                </p>

                <h3>1.3 Revisões clínicas e pré-clínicas</h3>
                <h4>1.3.1 Pré-clínica</h4>
                <p>
                  Revisões pré-clínicas tendem a incluir muitos estudos. Os
                  estudos incluídos tendem a ter pequenos tamanhos de amostra e
                  design experimental variado. As revisões pré-clínicas podem
                  ser usadas para:
                </p>
                <ul>
                  <li>Investigar falha translacional</li>
                  <li>
                    Explorar diferenças entre estudos (heterogeneidade), por
                    exemplo, validade interna e externa
                  </li>
                  <li>
                    Informar futuros estudos pré-clínicos, por exemplo, seleção
                    de modelo
                  </li>
                  <li>Informar os ensaios clínicos em fase inicial</li>
                  <li>
                    Explicar as discrepâncias nos resultados de ensaios
                    pré-clínicos vs. clínicos
                  </li>
                  <li>Informar as decisões dos 3Rs</li>
                </ul>
                <h4>1.3.2 Clínica</h4>
                <p>
                  As revisões clínicas tendem a incluir menos estudos, com
                  tamanhos de amostra maiores e a variabilidade entre os estudos
                  incluídos é reduzida com critérios de inclusão mais rigorosos.
                  As revisões clínicas podem ser usadas para:
                </p>
                <ul>
                  <li>
                    Explorar a heterogeneidade, por exemplo, populações clínicas
                  </li>
                  <li>Informar estudos clínicos em fase avançada</li>
                  <li>Informar a prática clínica e as diretrizes</li>
                </ul>

                <h3>1.4 Por que realizar RSs pré-clínicas?</h3>
                <ul>
                  <li>
                    Para resumir evidências de vários estudos semelhantes para
                    permitir estimativas de efeito mais precisas
                  </li>
                  <li>
                    Os métodos usados para encontrar e selecionar estudos são
                    transparentes e reproduzíveis, reduzindo o enviesamento e
                    aumentando a probabilidade de produzir conclusões confiáveis
                    e precisas.
                  </li>
                  <li>
                    Para resumir as descobertas de todos os estudos disponíveis,
                    tornando as informações mais fáceis para o usuário final ler
                    e entender
                  </li>
                  <li>
                    Analisar a qualidade de estudos individuais para apresentar
                    confiança nos resultados
                  </li>
                  <li>Síntese quantitativa dos resultados (meta-análise)</li>
                  <li>Permitir deduções baseadas em evidências</li>
                </ul>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/evidence-triangle.jpeg"
                      alt="triangle"
                    />
                  </div>
                </p>
                <p>
                  Os resultados de revisões sistemáticas pré-clínicas podem:
                </p>
                <ul>
                  <li>
                    Fornecer evidências para mudar a prática de pesquisa,
                    identificando riscos de enviesamento em experimentos
                    pré-clínicos
                  </li>
                  <li>
                    Influenciar o desenvolvimento de diretrizes para relatório
                    de pesquisa e políticas editoriais
                  </li>
                  <li>
                    Fornecer evidências para estimular a divulgação de
                    resultados positivos, negativos e neutros por meio da
                    detecção de viés de publicação
                  </li>
                  <li>
                    Identificar as características do desenho do estudo que
                    comprometem uma potencial aplicação clínica
                  </li>
                  <li>
                    Contribuir para o desenho de ensaios clínicos baseados em
                    evidências
                  </li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="#20">
                <h3 className="fw-bold">2 Revisões sistemáticas e 3Rs</h3>
                <p>
                  Os princípios dos 3Rs (Substituição, Redução e Refinamento -
                  Replacement, Reduction, and Refinement, em inglês) são uma
                  abordagem para a pesquisa humanizada com animais. A revisão
                  sistemática é uma ferramenta valiosa para o avanço dos 3Rs,
                  principalmente por meio da redução e refinamento do uso de
                  animais em pesquisas. Usando dados animais existentes, a
                  revisão sistemática pode contribuir para melhorias nos estudos
                  com animais, incluindo:
                </p>
                <ul>
                  <li>
                    Fornecer dados confiáveis para corroborar cálculos de
                    tamanho amostral para vários resultados experimentais
                  </li>
                  <li>
                    Permitir a comparação do desempenho estatístico de
                    diferentes medidas de resultados experimentais
                  </li>
                  <li>
                    Caracterizar até que ponto submeter animais a múltiplos
                    testes contribui para o conhecimento adicional
                  </li>
                  <li>
                    Avaliar se a mesma informação pode ser fornecida através de
                    testes menos invasivos
                  </li>
                  <li>
                  Para mais exemplos de revisões sistemáticas que implementam os 3Rs e o bem-estar animal, consulte {" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://doi.org/10.3390/ani9121163"
                  >
                    Ritskes-Hoitinga & van Luijk, 2019
                  </a>.
                  </li>
                  <li>
                  E a Diretriz para Relato de Pesquisa Animal: {" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://doi.org/10.1371/journal.pbio.3000410"
                  >
                    ARRIVE 2.0
                  </a>.
                  </li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="#30">
                <h3 className="fw-bold">3 Antes de Começar</h3>
                <p>
                  Há algumas coisas a serem verificadas antes de iniciar sua RS.
                  Leia mais abaixo.
                </p>
                <h3>3.1 Ela é necessária?</h3>
                <p>Considere o seguinte antes de iniciar sua RS:</p>
                <ul>
                  <li>A questão tem relevância contemporânea?</li>
                  <li>
                    A pergunta tem importância clínica ou importância para
                    informar sobre projetos de experimentos com animais?
                  </li>
                  <li>Existe, atualmente, variação na prática?</li>
                  <li>Há incerteza e debate na área?</li>
                  <li>
                    {" "}
                    Informando um design definitivo de experimentos em animais
                  </li>
                </ul>

                <h3>3.2 Isso já foi feito antes?</h3>
                <p>
                  Faça uma pesquisa rápida no{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://pubmed.ncbi.nlm.nih.gov/"
                  >
                    PubMed
                  </a>{" "}
                  ou na base de dados bibliográficos mais usada em sua área para
                  verificar as revisões sistemáticas publicadas. Além disso,
                  verifique os arquivos de pré-publicações, em sites como{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.biorxiv.org/"
                  >
                    bioRxiv
                  </a>
                  ,{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.medrxiv.org/"
                  >
                    medRxiv
                  </a>{" "}
                  ou{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://osf.io/"
                  >
                    OSF
                  </a>
                  , para ver se uma revisão sistemática foi publicada como uma
                  pré-publicação. Verifique se há revisões sistemáticas em
                  andamento no{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.crd.york.ac.uk/prospero/"
                  >
                    PROSPERO
                  </a>
                  .
                </p>
                <p>
                  As perguntas a serem feitas sobre as revisões sistemáticas
                  existentes na área incluem:
                </p>
                <ul>
                  <li>
                    A pergunta de pesquisa foi abordada de forma adequada?
                  </li>
                  <li>
                    A metodologia da revisão sistemática utilizada na revisão
                    tem qualidade?
                  </li>
                  <li>
                    A pergunta de pesquisa é específica ou ampla o suficiente
                    para o seu objetivo?
                  </li>
                  <li>Há quanto tempo a revisão sistemática foi realizada?</li>
                </ul>
                <p>
                  Não há necessidade de iniciar uma revisão sistemática se uma
                  RS recente, existente e de alta qualidade responder à sua
                  pergunta de pesquisa. Se houver uma RS relevante que não
                  esteja atualizada, considere entrar em contato com a equipe do
                  autor original para discutir seus planos de atualização da
                  revisão ou uma possível colaboração.
                </p>
                <p>
                  Para leitura adicional sobre como avaliar a qualidade de uma
                  revisão sistemática publicada, consulte as diretrizes PRISMA e
                  outras diretrizes apropriadas na{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://www.equator-network.org"
                    target="_blank"
                  >
                    página do EQUATOR
                  </a>
                  .
                </p>

                <h3>3.3 Existe uma já em andamento?</h3>
                <p>
                  Antes de começar, verifique se a revisão que você está
                  interessado em conduzir já não está sendo desenvolvida por
                  outro grupo de pesquisa.
                </p>
                <p>
                  Onde posso encontrar esta informação? Verifique os locais onde
                  um protocolo de revisão sistemática pode ser pré-registrado ou
                  publicado, como a{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.crd.york.ac.uk/prospero/"
                  >
                    PROSPERO
                  </a>
                  ,{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://osf.io/"
                  >
                    OSF
                  </a>
                  ,{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://syrf.org.uk/"
                  >
                    SyRF
                  </a>
                  , servidores de pré-publicação da sua área, como{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://www.biorxiv.org/"
                    target="_blank"
                  >
                    bioRxiv
                  </a>{" "}
                  ou{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.medrxiv.org/"
                  >
                    medRxiv
                  </a>
                  . Veja mais abaixo:{" "}
                  <a rel="noopener noreferrer" target="_blank" href="##50">
                    Registre seu protocolo
                  </a>
                  .
                </p>
                <p>Se você não encontrar nada, vá em frente e inicie sua RS.</p>
                <p>
                  Se você descobrir que alguém está trabalhando com uma mesma
                  pergunta de pesquisa ou em uma pergunta semelhante, entre em
                  contato com a equipe. Pergunte sobre seus objetivos, métodos e
                  em que estágio da RS eles estão e se você pode colaborar para
                  alcançar o objetivo comum.
                </p>

                <h3>3.4 Construa sua equipe de revisão sistemática</h3>
                <p>
                  Uma revisão sistemática pode levar muito tempo, portanto,
                  certifique-se de ter a experiência e o financiamento adequados
                  para concluir a revisão. Chame seus colegas para ajudar! E
                  entre em contato com pessoas fora de sua equipe para obter
                  aconselhamento especializado.
                </p>
                <p>
                  <b>Bibliotecários</b> e especialistas em informação podem
                  ajudar a refinar sua estratégia de busca. Eles terão insights
                  sobre quais bancos de dados bibliográficos contêm a literatura
                  sobre as áreas e tópicos de seu interesse. Os bibliotecários
                  podem ajudá-lo a identificar fontes de literatura cinzenta e
                  poderão lhe auxiliar a encontrar versões com o texto integral
                  de artigos que você deseja incluir em sua revisão,
                  especialmente se eles não estiverem disponíveis através da sua
                  assinatura institucional.
                </p>
                <p>
                  <b>Metodologistas de Revisão Sistemática</b>: Se você é novo
                  nos métodos de revisão sistemática, um metodologista poderá
                  ajudá-lo a planejar e organizar sua revisão, recomendar
                  softwares e ferramentas, bem como fornecer suporte para a
                  meta-análise.
                </p>
                <p>
                  Você pode precisar de aconselhamento adicional de um{" "}
                  <b>especialista em estatística</b> para a meta-análise, e é
                  bom envolver alguém o mais cedo possível no processo de
                  revisão.
                </p>
                <p>
                  <b>Conhecimento do tópico</b>: Certifique-se de que você tenha
                  em sua equipe pesquisadores e outras partes interessadas com
                  conhecimento adequado do tópico.
                </p>
                <p>
                  <b>Gestão do Projeto</b>: A realização de uma revisão
                  sistemática requer uma gestão eficaz do projeto. Certifique-se
                  de que haja um <b>líder</b> explícito e dedicado que
                  supervisionará o projeto durante todo o processo. O líder do
                  projeto é responsável por manter a visão geral, em qual
                  estágio está a revisão e convidar diferentes membros para a
                  equipe quando necessário. No início do processo de revisão, é
                  necessário decidir uma padronização de nomenclatura para
                  documentos e um local compartilhado para armazenar todos os
                  documentos relacionados à revisão. Pode ser necessário voltar
                  a algum estágio da revisão e rever decisões ou encontrar
                  informações, portanto, tenha um bom controle dos registros.
                  Tome notas detalhadas das decisões tomadas ao longo do
                  processo da RS, quaisquer desvios do protocolo. Essa não é
                  apenas uma prática boa e que aumenta a transparência, mas
                  também pode ajudar a garantir que todos os membros da equipe
                  estejam na mesma página.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#40">
                <h3 className="fw-bold">4 Pergunta de Pesquisa</h3>
                <p>
                  O primeiro passo é definir a sua pergunta de pesquisa. Uma
                  pergunta de pesquisa concisa é a base para uma boa estratégia
                  de busca, pois determina a estrutura e a sequência de suas
                  pesquisas na literatura.
                </p>
                <p>
                  As perguntas de pesquisa de RSs pré-clínicas geralmente são
                  centradas em uma intervenção ou exposição e adotam a seguinte
                  estrutura:
                </p>
                <p>
                  <b>P</b>opulação, participantes ou problema: Quais são as
                  características da população ou participantes (espécie, sexo,
                  estágio de desenvolvimento, fatores de risco, ou, para
                  participantes humanos, demografia, condições pré-existentes,
                  etc)? Qual é a condição ou doença de interesse?
                </p>
                <p>
                  <b>I</b>ntervenção ou Exposição: Qual é a intervenção ou
                  exposição em consideração para esta população?
                </p>
                <p>
                  <b>C</b>omparação: Qual é a alternativa para a intervenção
                  (por exemplo, placebo, medicamento diferente, cirurgia)?
                </p>
                <p>
                  <b>R</b>esultado/<b>D</b>esfecho: Quais são os resultados relevantes (por
                  exemplo, qualidade de vida, mudança no estado clínico,
                  morbidez, efeitos adversos, complicações)?
                </p>
                <p>
                  Existem outras estruturas de perguntas de pesquisa, dependendo
                  da sua área ou tópico de interesse, por exemplo, revisões de
                  testes diagnósticos e revisões prognósticas. Para obter mais
                  informações, consulte este artigo (em inglês) sobre{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://journals.library.ualberta.ca/eblip/index.php/EBLIP/article/view/9741/8144"
                  >
                    Como Formular Perguntas de Revisão
                  </a>
                  .
                </p>

                <h3>4.1 Partes Interessadas</h3>
                <p>
                  Envolva as partes interessadas no início da fase de revisão
                  para garantir que a pergunta de pesquisa e as descobertas da
                  revisão sejam relevantes.
                </p>
                <p>
                  Considere o seguinte:
                  <ul>
                    <li>
                      Quem usará os resultados de sua revisão sistemática?
                    </li>
                    <li>
                      Do ponto de vista deles, quais são as perguntas relevantes
                      a serem feitas?
                    </li>
                  </ul>
                </p>

                <h3>4.2 Exemplos para revisões pré-clínicas</h3>
                <p>
                  Para referência, veja exemplos de perguntas de pesquisa para
                  revisões publicadas.
                </p>
                <p>
                  "Qual é o efeito dos antidepressivos em comparação com o
                  veículo ou com o não tratamento no volume do infarto em
                  modelos animais de acidente vascular cerebral isquêmico?"
                </p>
                <p>
                  P - Modelos animais de acidente vascular cerebral isquêmico
                </p>
                <p>I - Antidepressivos</p>
                <p>C - Veículo ou sem tratamento</p>
                <p>R - Volume do infarto</p>
              </Tab.Pane>
              <Tab.Pane eventKey="#50">
                <h3 id="50" className="fw-bold">5 Protocolo</h3>
                <p className="fw-bold">
                  O que é um protocolo e por que ter um?
                </p>
                <p>
                  Um protocolo de revisão sistemática descreve porquê e como
                  você conduzirá sua revisão sistemática. Deve incluir sua
                  pergunta de pesquisa, contextualização e os métodos que serão
                  usados, incluindo: estratégia de busca, critérios de inclusão,
                  extração de dados, avaliação de qualidade, síntese de dados e
                  plano de análise estatística.
                </p>
                <p>
                  Ter um protocolo pré-especificado melhora a transparência
                  metodológica de sua revisão sistemática e reduz o risco de
                  enviesamento. A publicação do seu protocolo permite que outras
                  pessoas localizem as revisões em andamento e permite a
                  replicação futura. O processo de montar seu protocolo
                  geralmente envolve a comunicação entre várias partes
                  interessadas importantes, você pode discutir isso com um grupo
                  de aconselhamento, especialistas externos ou seus
                  financiadores.
                </p>

                <h3>5.1 Modelos de Protocolo</h3>
                <p>
                  O SYRCLE (SYStematic Review Centre for Laboratory Animal
                  Experimentation - Centro de Revisão Sistemática para
                  Experimentação com Animais em Laboratório) desenvolveu um
                  modelo de protocolo adaptado para a preparação, registro e
                  publicação de revisões sistemáticas de estudos de intervenção
                  em animais.{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://onlinelibrary.wiley.com/doi/epdf/10.1002/ebm2.7"
                  >
                    Veja o modelo e a publicação aqui
                  </a>
                  .
                </p>
                <p>
                  Também pode ser útil olhar exemplos do{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://syrf.org.uk/protocols"
                  >
                    SyRF Protocol Registry
                  </a>{" "}
                  (Registros de Protocolos SyRF) enquanto você formula seu
                  protocolo. Consulte o Registro de Protocolos para verificar se
                  nenhuma revisão sistemática com a mesma pergunta de pesquisa
                  está em andamento no momento.
                </p>

                <h3>5.2 Registre seu protocolo</h3>
                <p>
                  Disponibilizar o protocolo para sua revisão sistemática à
                  comunidade têm vários benefícios: fornece evidências de que
                  análises pré-especificadas foram de fato pré-especificadas;
                  permite que outros comentem sobre sua abordagem; fornece
                  exemplos para outros que planejam tais revisões; e pode
                  ajudá-lo a identificar se outras revisões em áreas semelhantes
                  já estão em andamento. Você pode pesquisar a lista de
                  protocolos por título, data, pessoa responsável ou
                  instituição.
                </p>
                <p>
                  PROSPERO: O Centro de Revisões e Disseminação da Universidade
                  de York agora publica os Protocolos de Revisão Sistemática
                  Pré-clínica. Para mais informações sobre como se registrar no
                  PROSPERO, consulte o{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.crd.york.ac.uk/prospero"
                  >
                    site aqui
                  </a>
                  .
                </p>
                <p>
                  OSF: Você pode pré-registrar seu projeto de revisão
                  sistemática no{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://osf.io/prereg"
                  >
                    Open Science Framework aqui
                  </a>
                  .
                </p>

                <h3>5.3 Seu protocolo e 3RS</h3>
                <p>
                  Recomendamos que você inclua uma declaração em seu protocolo
                  descrevendo como sua pesquisa afetará os 3Rs (Substituição,
                  Redução e Refinamento) no uso de animais em pesquisa.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#60">
                <h3 className="fw-bold">6 Busca Sistemática</h3>
                <p>
                  Para identificar estudos relevantes para incluir em sua RS,
                  você precisa realizar uma pesquisa bibliográfica abrangente
                  com base em uma estratégia de busca bem projetada.
                </p>

                <h3 id="60">6.1 Selecionando Bases de Dados</h3>
                <p>Bases de dados:</p>
                <p>
                  O primeiro passo é decidir em quais bases de dados pesquisar,
                  isso vai depender da sua área de pesquisa e pergunta de
                  pesquisa. As bases de dados diferem em sua cobertura de
                  periódicos e como os artigos são indexados. Para pesquisas
                  pré-clínicas, bancos de dados típicos incluem{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov"
                  >
                    PubMed
                  </a>
                  , Embase e Web of Science. Um bibliotecário ou especialista em
                  bases de dados bibliográficos poderá ajudá-lo a identificar
                  outras bases de dados em potencial e construir termos de
                  pesquisa específicos para usar na busca. É uma prática comum
                  pesquisar em várias bases de dados para garantir uma cobertura
                  adequada e eficiente.
                </p>
                <p>
                  Além de bases de dados eletrônicas, você pode usar outros
                  métodos para encontrar artigos relevantes, tais como: escanear
                  listas de referência de estudos relevantes (estudos primários
                  e revisões), pesquisar manualmente os principais periódicos,
                  entrar em contato com especialistas na área e pesquisar outras
                  fontes relevantes na internet. Mantenha um registro dos
                  métodos alternativos usados e os dados coletados em um formato
                  estruturado.
                </p>

                <h4>PubMed</h4>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov"
                  >
                    PubMed
                  </a>{" "}
                  é um banco de dados bibliográfico composto por mais de 30
                  milhões de citações de literatura biomédica do MEDLINE,
                  periódicos de ciências da vida e livros online.
                </p>
                <p>
                  É um recurso gratuito que apoia a busca e recuperação de
                  literatura biomédica e ciências da vida com o objetivo de
                  melhorar a saúde. É mantido pelo Centro Nacional de
                  Informações sobre Biotecnologia (NCBI) da Biblioteca Nacional
                  de Medicina dos EUA.
                </p>
                <p>
                  Links e recursos: O{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pubmed/advanced"
                  >
                    PubMed Advanced Search Builder
                  </a>{" "}
                  é uma ferramenta útil para construir sua consulta de pesquisa.
                </p>
                <p>
                  Informações sobre{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.nlm.nih.gov/mesh/meshhome.html"
                  >
                    MeSH Headings
                  </a>
                  .
                </p>

                <h4>Embase</h4>
                <p>
                  O Embase é um banco de dados de pesquisa biomédica que cobre a
                  literatura de 1947 até os dias atuais. Ele contém mais de 32
                  milhões de registros, incluindo títulos MEDLINE e artigos de
                  2.900 periódicos exclusivos do Embase.
                </p>
                <p>
                  Você pode acessar o Embase diretamente ou através do Ovid,
                  dependendo da sua assinatura.
                </p>
                <p>
                  Mais informações sobre os arquivos do Embase e o EmTree
                  Headings podem ser{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.elsevier.com/solutions/embase-biomedical-research/embase-coverage-and-content"
                  >
                    encontradas aqui
                  </a>
                  .
                </p>

                <h4>Web of Science</h4>
                <p>
                  Web of Science é uma base de dados de citações independente de
                  editores. A Web of Science Core Collection indexa periódicos
                  acadêmicos, livros e procedimentos nas áreas de ciências,
                  ciências sociais e artes e humanidades e pode ser usada para
                  navegar em toda a rede de citações.
                </p>
                <p>
                  A Web of Science também pode ser usada para pesquisar outras
                  bases de dados, incluindo SciELO, KCI-Korean Journal Database
                  e Zoological Record.
                </p>

                <h4>Outras fontes e literatura cinzenta</h4>
                <p>Outras bases de dados bibliográficos incluem:</p>
                <p>
                  <ul>
                    <li>
                      O Cochrane Central Register of Controlled Trials{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.cochranelibrary.com/central/about-central"
                      >
                        (CENTRAL)
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://scholar.google.com/"
                      >
                        Google Scholar
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.scopus.com/home.uri"
                      >
                        Scopus
                      </a>
                    </li>
                    <li>
                      Cumulative Index to Nursing and Allied Health Literature{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.ebscohost.com/nursing/products/cinahl-databases/cinahl-complete"
                      >
                        CINAHL
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.apa.org/pubs/databases/psycinfo"
                      >
                        PsycINFO
                      </a>
                    </li>
                  </ul>
                </p>
                <p>
                  O acesso pode variar dependendo do acesso institucional.
                  Documente sua estratégia de busca para que seja
                  suficientemente reproduzível.
                </p>

                <h3>6.2 Desenvolvimento de estratégia de busca</h3>
                <p>
                  Selecione seus termos de pesquisa com base em cada um dos
                  conceitos PICR (ou equivalentes) em sua pergunta de pesquisa.
                </p>

                <h4>6.2.1 Etapa 1</h4>
                <p>
                  <b>
                    Etapa 1: encontre palavras-chave e sinônimos para cada
                    elemento
                  </b>
                </p>
                <p>
                  Um bom exercício é pensar no máximo de sinônimos possível para
                  cada um dos principais conceitos ou elementos PICO.
                </p>
                <p>Por exemplo:</p>
                <p>
                  Se sua pergunta de pesquisa for: Qual é o efeito dos
                  antidepressivos em comparação com o veículo ou com o não
                  tratamento no volume do infarto em modelos animais de acidente
                  vascular cerebral?
                </p>
                <p>
                  População: AVC. Sinônimos podem incluir: isquemia cerebral,
                  acidente vascular cerebral.
                </p>
                <p>
                  Intervenção: Antidepressivos. Sinônimos podem incluir:
                  fluoxetina, SSRIs
                </p>

                <h4>6.2.2 Etapa 2</h4>
                <p>
                  <b>
                    Etapa 2: termos de índice/assunto (específicos da base de
                    dados)
                  </b>
                </p>
                <p>
                  Cada base de dados central tem seu próprio sistema de
                  indexação de termos, tópicos e assuntos. Verifique quais os
                  títulos de assunto e termos de indexação presentes nas bases
                  de dados que você está interessado em pesquisar antes de
                  começar.
                </p>
                <p>
                  <ul>
                    <li>Termos MeSH</li>
                    <li>Termos Emtree</li>
                    <li>
                      (Veja mais informações sobre MeSH e EMTREE acima de{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#60"
                      >
                        Selecionando bases de dados
                      </a>
                      )
                    </li>
                  </ul>
                </p>
                <p>
                  Por que usar palavras-chave e termos indexados em sua
                  estratégia de busca?
                </p>
                <p>
                  Os artigos no PubMed são indexados manualmente, mas geralmente
                  há um pequeno atraso. Para capturar todos os artigos que usam
                  linguagem não padrão, incluindo os publicados recentemente,
                  você pode perder alguns usando apenas uma pesquisa por
                  palavra-chave.
                </p>

                <h4>6.2.3 Etapa 3</h4>
                <p>
                  <b>Etapa 3: Combinando termos de busca</b>
                </p>
                <p>Operadores booleanos</p>
                <p>
                  O operador OR é usado para conectar dois ou mais conceitos
                  semelhantes (sinônimos). Ele é usado para ampliar os
                  resultados informando ao banco de dados que pelo menos um dos
                  termos de pesquisa deve estar presente nos resultados.
                </p>
                <p>
                  O operador AND é usado para restringir os resultados. Ele é
                  usado para informar ao banco de dados que todos os termos de
                  pesquisa devem estar presentes em cada resultado.
                </p>
                <p className="row">
                  <div className="col-6">
                    <div className="text-center">
                      <StaticImage
                        className="my-3"
                        src="../../../assets/images/wikis/pt/wiki/booleanOR.jpeg"
                        alt="booleanOR"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text-center">
                      <StaticImage
                        className="my-3"
                        src="../../../assets/images/wikis/pt/wiki/booleanAND.jpeg"
                        alt="booleanAND"
                      />
                    </div>
                  </div>
                </p>

                <h4>Precisão e Sensibilidade</h4>
                <p>
                  <b>Precisão</b> é a capacidade da estratégia de busca de
                  excluir artigos irrelevantes.
                </p>
                <p>
                  <b>Sensibilidade</b> é a capacidade da estratégia de busca de
                  identificar todos os artigos relevantes.
                </p>
                <p>
                  O objetivo é <b>maximizar a sensibilidade</b> ao tentar{" "}
                  <b>maximizar a precisão</b>.
                </p>

                <h4>Dicas e Truques</h4>
                <p>
                  <ul>
                    <li>
                      Leve em consideração as diferenças de ortografia (por
                      exemplo, inglês dos EUA versus inglês do Reino Unido)
                    </li>
                    <li>
                      Considere o uso de outros campos PubMed, por exemplo, MeSH
                      SubHeadings [SH] ou Pharmacological Action [PA]. Encontre
                      mais informações aqui:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://pubmed.ncbi.nlm.nih.gov/help/#search-tags"
                      >
                        Tags de pesquisa do PubMed
                      </a>
                    </li>
                    <li>
                      Ao usar o operador NOT, leve em consideração qual
                      literatura relevante você pode estar excluindo. -
                      Considere símbolos de truncamento ou “curingas” para sua
                      pesquisa (por exemplo, ischem* para ischemia e ischemic,
                      etc). Verifique se todas as bases de dados bibliográficas
                      permitem isso antes de adicionar à sua pesquisa.
                    </li>
                  </ul>
                </p>

                <h3>6.3 Executando pesquisas e combinando resultados</h3>
                <p>
                  Depois de ter composto os principais componentes da sua
                  estratégia de busca, você pode executar pesquisas nas bases de
                  dados de sua escolha.
                </p>
                <p>
                  <b>
                    1.Execute cadeias de busca em bases de dados especificadas.
                  </b>
                </p>
                <p>
                  O Polyglot Search Translator é uma ferramenta que o ajudará a
                  traduzir a sintaxe de sua cadeia de busca em várias bases de
                  dados. Para mais informações sobre o{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://sr-accelerator.com/#/polyglot"
                  >
                    Polyglot Search Translator, clique aqui
                  </a>
                  .
                </p>
                <p>
                  <b>
                    2.Combine os resultados em um software gerenciador de
                    referências, como o{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://endnote.com/"
                    >
                      EndNote
                    </a>{" "}
                    ou{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.zotero.org"
                    >
                      Zotero
                    </a>
                  </b>
                </p>
                <p>
                  Para encontrar pdfs de textos integrais com mais facilidade,
                  lembre-se de adicionar as informações de assinatura em
                  bibliotecas nas configurações ou preferências do gerenciador
                  de referências, por exemplo, informações de assinatura no
                  EzProxy ou OpenURL.
                </p>

                <p>
                  A ordem de importação importa? <b>SIM!</b>
                </p>
                <p>
                  A ordem em que você importa suas referências para o Endnote ou
                  outro gerenciador de referências é importante. Diferentes
                  bases de dados bibliográficos têm qualidade ou completude
                  diferente das referências nas quais você está interessado, e
                  os gerenciadores de referência usam essas informações para
                  deduplicar os resultados (o próximo passo).
                </p>
                <p>
                  A{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://blogs.lshtm.ac.uk/library/2018/12/07/removing-duplicates-from-an-endnote-library"
                  >
                    ordem recomendada
                  </a>{" "}
                  é:
                </p>

                <p>
                  <ol>
                    <li>Medline</li>
                    <li>Embase</li>
                    <li>Medline em processo (se incluído)</li>
                    <li>
                      Outras bases de dados do OvidSP (PsycInfo, EconLit etc)
                    </li>
                    <li>PubMed</li>
                    <li>Cinahl Plus</li>
                    <li>Outras bases de dados da Ebsco</li>
                    <li>Bases de dados da Web of Science</li>
                    <li>Scopus</li>
                    <li>Bases de dados da ProQuest</li>
                    <li>Bases de dados Cochrane</li>
                    <li>Bases de dados CRD</li>
                    <li>Quaisquer outras bases de dados</li>
                    <li>Sites de ensaios clínicos</li>
                  </ol>
                </p>

                <h3>6.4 Deduplicação</h3>
                <p>
                  Você pesquisou várias bases de dados diferentes e outras
                  fontes. Provavelmente há duplicatas ou sobreposições. O tempo
                  gasto na deduplicação de suas referências garantirá que você
                  tenha números precisos (total de
                  registros/incluídos/excluídos) para documentar e não perder
                  tempo analisando duplicatas.
                </p>
                <p>
                  As ferramentas para ajudar a remover referências duplicadas
                  incluem:
                </p>
                <ul>
                  <li>
                    O Endnote pode ser usado para localizar e remover registros
                    duplicados. Veja{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doi.org/10.3163/1536-5050.104.3.014"
                    >
                      este recurso
                    </a>
                    .
                  </li>
                  <li>
                    Ferramentas independentes, como a{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doi.org/10.1186/2046-4053-4-6"
                    >
                      SR-Accelerator Tool
                    </a>{" "}
                    e a{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://camarades.shinyapps.io/RDedup"
                    >
                      ferramenta ASySD
                    </a>{" "}
                    para revisões pré-clínicas.
                  </li>
                </ul>

                <h3>6.5 Atualize sua pesquisa e ferramentas</h3>
                <p>
                  O SyRF - Systematic Review Facility possui uma função
                  integrada que pode recuperar automaticamente novos registros
                  que atendam à sua cadeia de busca do PubMed. Para obter mais
                  informações, consulte o{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://camaradesbrasil.bio.br/guia-syrf/"
                  >
                    Guia de Ajuda do SyRF aqui
                  </a>
                  .
                </p>
                <p>
                  O Polyglot Search Translator é uma ferramenta que o ajudará a
                  traduzir a sintaxe de sua cadeia de busca em várias bases de
                  dados. Para mais informações sobre o{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://sr-accelerator.com/#/polyglot"
                  >
                    Polyglot Search Translator, clique aqui
                  </a>
                  .
                </p>

                <h3>6.6 Localizar e recuperar textos integrais</h3>
                <p>
                  Depois de ter sua biblioteca de referências exclusivas, você
                  pode encontrar e recuperar os textos integrais.
                </p>
                <p>
                  <ol>
                    <li>
                      Use seu gerenciador de referências. Guias para
                      recuperação no{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/watch?v=ZivpT7wMMrY"
                      >
                        Endnote
                      </a>{" "}
                      e{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.zotero.org/support/locate"
                      >
                        Zotero
                      </a>{" "}
                      podem ser encontrados nos respectivos links.
                      <p>
                        OBS.: Lembre-se de adicionar suas informações de Login
                        Institucional às configurações ou preferências do
                        gerenciador de referências, por exemplo, do{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://ezproxy-db.appspot.com/"
                        >
                          EzProxy
                        </a>{" "}
                        ou{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.zotero.org/support/locate/openurl_resolvers"
                        >
                          OpenURL
                        </a>
                        , para que você possa encontrar mais facilmente os
                        textos integrais aos quais a biblioteca institucional
                        tem acesso.
                      </p>
                    </li>
                    <li>
                      Pesquisa Online: Pesquisa do Google, Google Scholar,
                      ResearchGate, etc.
                    </li>
                    <li>
                      Entre em contato com os autores correspondentes
                      diretamente por e-mail ou Twitter.
                    </li>
                    <li>
                      Último recurso: peça ao seu bibliotecário para ajudar
                      com empréstimos entre bibliotecas. (OBS.: estes podem
                      custar muito caro!)
                    </li>
                  </ol>
                </p>
                <p>
                  OBS.: Tenha cuidado ao usar scripts personalizados ou outros
                  programas para download em massa, pois isso pode resultar no
                  bloqueio do seu endereço IP institucional!
                </p>
                <p>
                  Se a sua estratégia de busca tiver recuperado muitos
                  resultados potencialmente relevantes, você pode considerar
                  esperar para encontrar os textos integrais apenas depois de
                  realizar a triagem de títulos e resumos (veja na próxima seção). Isso
                  reduzirá bastante o número de registros de textos integrais
                  que precisará encontrar e você não perderá tempo tentando
                  encontrar artigos que não sejam relevantes para a sua pergunta
                  de pesquisa.{" "}
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#70">
                <h3 className="fw-bold">7 Seleção de estudos</h3>
                <p>
                  Depois de encontrar artigos que podem ser potencialmente
                  relevantes para a sua pergunta de pesquisa, é necessário
                  avaliar a relevância de cada artigo em relação a critérios
                  predefinidos.
                </p>
                <p>Se aplicável, você pode fazer isso em duas etapas:</p>
                <ul>
                  <li>Triagem de Título ou Título e Resumo</li>
                  <li>Triagem do texto integral</li>
                </ul>

                <h3>7.1 Critérios de Inclusão e Exclusão</h3>
                <p>
                  Definir os critérios de inclusão e exclusão delimita a sua
                  revisão. É importante que os critérios sejam pré-definidos,<i>a
                  priori</i>, e aplicados de forma consistente em todos os estudos
                  contemplados na revisão. Para garantir isso, é comum fazer
                  triagem de citações em dupla, duas revisões independentes, com
                  discussão ou um terceiro revisor independente para conciliar
                  eventuais discordâncias.
                </p>
                <p>
                  Os critérios de inclusão referem-se a tudo que um estudo deve
                  ter para ser incluído em sua revisão. Os critérios de exclusão
                  referem-se a fatores que tornam um estudo inelegível para
                  inclusão.
                </p>
                <p>
                  Normalmente, os critérios de inclusão e exclusão são definidos
                  acerca de:
                </p>
                <p>
                  <ul>

                    <li>
                      Tipo de população (por exemplo, idade, sexo, modelo de
                      doença)
                    </li>
                    <li>
                      Tipo de intervenção (por exemplo, dosagem, tempo de
                      intervenção, frequência)
                    </li>
                    <li>
                      Tipo de Medição de Resultado (por exemplo, parâmetros
                      relacionados ao método de avaliação ou aparelho)
                    </li>
                  </ul>
                </p>
                <p>
                  Fatores adicionais que você pode querer levar em consideração:
                </p>
                <p>
                  <ul>
                    <li>
                      Restrições de idioma (quais idiomas sua equipe de revisão
                      pode traduzir?)
                    </li>
                    <li>Restrições de data de publicação</li>
                    <li>
                      Tipo de publicação (por exemplo, resumos de conferências,
                      revisão por pares)
                    </li>
                  </ul>
                </p>
                <p>
                  Você pode considerar priorizar os critérios de inclusão e
                  exclusão com base em quais critérios você provavelmente
                  aplicará na fase de título e resumo e quais critérios você só
                  poderá aplicar depois de ler o texto completo.
                </p>

                <h3>7.2 Aplique seus critérios</h3>
                <p>
                  Um estudo foi incluído ou excluído em sua revisão? Um estudo é
                  relevante, ou não relevante, para sua pergunta de pesquisa com
                  base em seus critérios pré-definidos?
                </p>
                <p>
                  Para garantir que seus critérios de inclusão e exclusão sejam
                  aplicados de maneira imparcial e uniforme, é uma boa escolha
                  ter pelo menos 2 avaliadores independentes para aplicá-los. Se
                  houver discrepâncias em suas decisões, você pode discuti-las
                  até chegar a um consenso ou convidar um terceiro revisor
                  independente para reconciliar quaisquer diferenças.
                </p>

                <h3>7.3 Ferramentas para triagem</h3>
                <p>
                  Você pode fazer a triagem de títulos e resumos e de textos
                  completos na{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://syrf.org.uk/"
                  >
                    SyRF
                  </a>{" "}
                  - Systematic Review Facility, que é uma plataforma on-line de
                  uso gratuito para auxiliar na condução da sua revisão
                  sistemática pré-clínica.
                </p>
                <p>
                  A SyRF apresenta os artigos em ordem aleatória para os
                  examinadores e, por padrão, exige um consenso entre múltiplos
                  examinadores.
                </p>
                <p>
                  Outras plataformas de uso gratuito para realizar triagem de
                  citações são{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://rayyan.ai"
                  >
                    Rayyan
                  </a>{" "}
                  e{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://sysrev.com/"
                  >
                    SysRev
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#80">
                <h3 className="fw-bold">8 Extração de dados</h3>
                <p>
                  Extraia dados relevantes conforme definido em seu protocolo.
                </p>
                <p>
                  É uma prática recomendada extrair dados em duplicata, dois
                  revisores independentes, para evitar erros.
                </p>

                <h3>8.1 Características do estudo</h3>
                <p>
                  As características do estudo para extrair dos artigos
                  incluídos incluem:
                </p>
                <p>
                  <ul>
                    <li>
                      Informações PICR (por exemplo, idade e sexo da população,
                      espécie e linhagem do animal, dose e momento da
                      intervenção, tipo e momento da avaliação do resultado)
                    </li>
                    <li>Informações sobre o design do estudo</li>
                    <li>
                      Informações sobre a qualidade do estudo{" "}
                      <a target="_blank" rel="noopener noreferrer" href="#90">
                        veja na próxima seção
                      </a>
                      )
                    </li>
                    <li>
                      Informações adicionais (por exemplo, tempo entre a
                      intervenção e a avaliação do resultado, qualquer
                      informação sobre comorbidades)
                    </li>
                  </ul>
                </p>

                <h3>8.2 Dados Quantitativos</h3>
                <p>
                  A extração de dados quantitativos e numéricos de estudos
                  incluídos é necessária para realizar a meta-análise para
                  reunir os tamanhos de efeito
                </p>
                <p>Seus resultados de interesse podem ser:</p>
                <p>
                  <ul>
                    <li>
                      Dicotômicos (por exemplo, mortalidade, presença de tumor){" "}
                    </li>
                    <div className="text-center">
                      <StaticImage
                        className="my-3"
                        src="../../../assets/images/wikis/pt/wiki/dichot-outcome.jpeg"
                        alt="outcome"
                      />
                    </div>
                    <li>
                      Contínuos (por exemplo, pressão arterial ou perda de peso){" "}
                    </li>
                    <div className="text-center">
                      <StaticImage
                        className="my-3"
                        src="../../../assets/images/wikis/pt/wiki/contin-outcome.jpeg"
                        alt="outcome"
                      />
                    </div>
                    <li>Dados de contagem (por exemplo, número de eventos)</li>
                  </ul>
                </p>

                <p>
                  Os dados sobre seus resultados podem ser fornecidos em vários
                  formatos, incluindo:
                </p>
                <p>
                  <ul>
                    <li>Tabelas</li>
                    <li>Texto</li>
                    <li>Gráficos</li>
                  </ul>
                </p>
                <p>
                  Você pode precisar usar ferramentas como{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://helpx.adobe.com/acrobat/using/grids-guides-measurements-pdfs.html"
                  >
                    Adobe desktop ruler
                  </a>{" "}
                  ou{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://automeris.io/WebPlotDigitizer"
                  >
                    WebPlotDigitizer
                  </a>{" "}
                  para extrair valores numéricos (por exemplo, médias e desvios
                  padrão (SD) ou erro padrão da média (SEM) de gráficos). Alguns
                  estudos podem relatar valores em uma escala diferente. Esteja
                  ciente de que pode ser necessário convertê-los para uma escala
                  comum a todos os estudos (por exemplo, conversão de escala
                  logarítmica).
                </p>

                <h3>8.3 Software de extração de dados</h3>
                <p>
                  À medida que essas informações são extraídas, você vai querer
                  armazená-las no mesmo lugar para facilitar a síntese e análise
                  posteriormente.
                </p>
                <p>
                  Recomendamos usar a{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://syrf.org.uk"
                  >
                    SyRF
                  </a>{" "}
                  - Systematic Review Facility para extrair e armazenar seus
                  dados. É uma plataforma online gratuita onde você pode criar
                  formulários de extração de dados personalizados para a sua
                  revisão. Tipos flexíveis de perguntas e configurações de
                  perguntas, bem como o formato on-line, permitem que você e sua
                  equipe consigam extrair fácil e simultaneamente dados dos
                  artigos incluídos. Para obter mais informações, consulte{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://syrf.org.uk/"
                  >
                    o site do SyRF
                  </a>{" "}
                  e o Guia de ajuda do SyRF (
                  <a
                    href="https://assets.syrf.org.uk/guides/SyRF_User_Guide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    inglês
                  </a>
                  /<Link to="/guia-syrf">português</Link>) para configurar seu
                  projeto gratuito de revisão.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#90">
                <h3 id="90" className="fw-bold">9. Avaliação de qualidade</h3>
                <p>
                  <b>Por que avaliar a qualidade do estudo?</b>
                </p>
                <p>
                  A baixa qualidade metodológica pode afetar a validade interna
                  e resultar em enviesamento nos resultados dos estudos
                  primários. A validade interna refere-se a até que ponto os
                  resultados do estudo refletem a verdadeira causa-efeito de uma
                  intervenção. Diferentes tipos de viés podem influenciar a
                  validade interna (por exemplo, vieses de seleção, desempenho,
                  detecção e atrito).
                </p>
                <p>
                  <b>Não é impacto. Não é novidade.</b>
                </p>
                <p>
                  O viés em estudos primários pode levar a uma super ou
                  subestimação do verdadeiro efeito da intervenção tanto em
                  estudos primários quanto em revisões sistemáticas. É
                  importante considerar as implicações da qualidade e validade
                  do estudo para interpretar os resultados de sua revisão
                  sistemática e muitas vezes é uma boa ideia incorporar uma
                  seção de avaliação de qualidade em seu relatório final.
                </p>
                <p>
                  As características de qualidade do estudo que demonstraram
                  impactar os resultados dos estudos pré-clínicos incluem se os
                  animais foram randomizados para grupos de controle ou de
                  tratamento e se os pesquisadores não tinham informações sobre
                  o grupo de animais ao avaliar os resultados.
                </p>
                <p>
                Leia mais sobre alocação e cegamento no site do{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://eda.nc3rs.org.uk/experimental-design-allocation"
                  >
                    <i>NC3Rs Experimental Design Assistant</i> 
                  </a>{" "}
                </p>
                <h3>9.1 Relatórios</h3>
                <p>
                  Use um checklist de verificação de qualidade para escrever os
                  relatórios.
                </p>
                <p>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.ahajournals.org/doi/pdf/10.1161/01.str.0000125719.25853.20"
                      >
                        Lista de Verificação do CAMARADES
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.3000410"
                      >
                        ARRIVE 2.0
                      </a>{" "}
                      Diretrizes para relatórios de pesquisas em animais
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.gene-quantification.de/checklist-for-life-sciences-articles.pdf"
                      >
                        Checklist de Relatórios da Nature
                      </a>
                      . O checklist operacionalizado está disponível{" "}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://link.springer.com/article/10.1007/s11192-016-1964-8/tables/6"
                      >
                        aqui
                      </a>
                      .
                    </li>
                  </ul>
                </p>

                <h3>9.2 Risco de viés</h3>
                <p>
                  Para ajudá-lo a avaliar a qualidade do estudo, use uma
                  ferramenta voltada à avaliação de qualidade ou risco de viés
                  (Risk of Bias-RoB). Entre as ferramentas que foram
                  desenvolvidas para avaliar o viés e a qualidade em estudos
                  pré-clínicos, encontra-se a{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://bmcmedresmethodol.biomedcentral.com/track/pdf/10.1186/1471-2288-14-43"
                  >
                    SYRCLE RoB
                  </a>
                  .
                </p>

                <h3>9.3 Avaliação RoB para informar a análise</h3>
                <p>
                  Um estudo que está em risco de ser tendencioso pode ter as
                  conclusões impactadas por um viés. Os achados da avaliação de
                  risco de enviesamento devem fazer parte das conclusões de sua
                  revisão sistemática.
                </p>
                <p>
                  <ul>
                    <li>
                      Realize análise de sensibilidade (qualitativamente, ou
                      quantitativamente usando meta-análise)
                    </li>
                    <li>
                      Exclua estudos com alto risco de viés da síntese de
                      evidências (isso deve ser feito com extrema cautela e deve
                      ser pré-especificado em seu protocolo para evitar
                      enviesamento)
                    </li>
                    <li>
                      Chegue a uma conclusão geral para cada resultado sobre se
                      o resultado sintetizado está em alto risco de viés
                    </li>
                    <li>
                      Use a conclusão geral para informar a avaliação resumida
                      da certeza da evidência usando, por exemplo, a{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://bestpractice.bmj.com/info/toolkit/learn-ebm/what-is-grade"
                      >
                        abordagem GRADE
                      </a>
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#100">
                <h3 className="fw-bold">10 Meta-análise</h3>
                <p>
                  <b>O que é uma meta-análise?</b>
                </p>
                <p>
                  Meta-análise é a combinação estatística de resultados de dois
                  ou mais estudos separados
                </p>

                <p>
                  <b>Por que realizar a Meta-análise?</b>
                </p>
                <p>
                  <ul>
                    <li>
                      Para fornecer um teste com mais poder do que estudos
                      separados
                    </li>
                    <li>Para fornecer uma melhoria na precisão estatística</li>
                    <li>Para resumir descobertas numerosas e inconsistentes</li>
                    <li>
                      Para investigar a consistência do efeito em diferentes
                      amostras
                    </li>
                  </ul>
                </p>
                <p>
                  <b>Que questões são abordadas?</b>
                </p>
                <p>
                  <ul>
                    <li>Qual é a direção do efeito?</li>
                    <li>Qual o tamanho do efeito?</li>
                    <li>
                      O efeito é consistente entre todos os estudos?
                      (heterogeneidade)
                    </li>
                    <li>
                      Qual é a força da evidência para o efeito? (avaliação de
                      qualidade)
                    </li>
                  </ul>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.cochrane-handbook.org"
                  >
                    (Referência: Cochrane Handbook)
                  </a>
                </p>
                <p>
                  Felizmente, software estatístico cuida da maior parte do
                  'trabalho pesado' quando se trata de calcular tamanhos de
                  efeito, reuni-los e fazer gráficos de floresta (forest plots).
                </p>
                <p>
                  Para entender o básico e obter equações exatas, continue a
                  leitura.
                </p>
                <p>
                  As equações mostradas abaixo são das seguintes referências,
                  onde mais informações podem ser encontradas:
                </p>
                <p>
                  <ul>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://doi.org/10.1016/j.jneumeth.2013.09.010"
                      >
                        “Meta-analysis of data from animal studies: a practical
                        guide.” Journal of neuroscience methods - Vesterinen et
                        al, 2014
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://doi.org/10.1002/9780470743386"
                      >
                        Introduction to Meta-Analysis - Borenstein et al., 2009
                      </a>
                    </li>
                  </ul>
                </p>

                <h3>10.1 Etapa 1. Calculando tamanho do efeito</h3>
                <p>
                  O primeiro passo é calcular o tamanho do efeito para cada
                  resultado dentro de cada um dos estudos.
                </p>
                <p>Seus resultados podem ser:</p>
                <p>
                  <ul>
                    <li>Contínuos</li>
                    <li>Dicotômicos</li>
                  </ul>
                </p>
                <h4>Contínuo</h4>
                <p>
                  Para <b>resultados contínuos</b>, as medidas de tamanho de
                  efeito comumente usadas incluem:
                </p>
                <p>
                  <ul>
                    <li>Diferença Média</li>
                    <li>Diferença de Médias Normalizadas</li>
                    <li>Diferença de Médias Padronizadas</li>
                  </ul>
                </p>
                <h5>Diferença Média</h5>
                <p>
                  <b>Diferença média: </b>A diferença média bruta pode ser usada
                  quando os resultados são relatados em uma escala significativa
                  e todos os estudos na análise usam a mesma escala. A
                  meta-análise é realizada diretamente na diferença bruta nas
                  médias.
                </p>
                <p>Tamanho do Efeito da Diferença Média:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f.png"
                    alt="Formula"
                  />
                </div>
                <p>Erro padrão:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(1).png"
                    alt="Formula"
                  />
                </div>
                <p>onde S combinado (pooled) é:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(2).png"
                    alt="Formula"
                  />
                </div>

                <h5>Diferença de Médias Normalizadas</h5>
                <p>
                  Diferença de médias normalizadas: A diferença média
                  normalizada (NMD) pode ser usada quando os resultados estão em
                  uma escala de razão, onde a pontuação em um animal 'controle'
                  ou 'placebo' é conhecida. O método mais comum para calcular o
                  NMD é como uma proporção da média.
                </p>
                <p>
                  O cálculo do tamanho do efeito para a diferença média
                  normalizada:
                </p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(3).png"
                    alt="Formula"
                  />
                </div>
                <p>Onde</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(4).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  é a pontuação média para o animal não lesionado/normal/não
                  tratado.
                </p>
                <p>Os cálculos de desvio padrão são os seguintes:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(5).png"
                    alt="Formula"
                  />
                </div>
                <p>e</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(6).png"
                    alt="Formula"
                  />
                </div>

                <p>O erro padrão do tamanho do efeito é:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(7).png"
                    alt="Formula"
                  />
                </div>
                <h5>Diferença de Médias Padronizadas</h5>
                <p>
                  <b>Diferença de médias padronizadas:</b> (SMD), o D de Cohen e
                  o G de Hedge. O SMD é usado quando a escala de medição difere
                  entre os estudos e não é significativo combinar diferenças
                  médias brutas. A diferença média em cada estudo é dividida
                  pela divisão padrão do estudo para criar um índice comparável
                  entre os estudos.
                </p>
                <p>Tamanho do efeito (SMD) G de Hedge:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(8).png"
                    alt="Formula"
                  />
                </div>
                <p>E o erro padrão do tamanho do efeito é:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(9).png"
                    alt="Formula"
                  />
                </div>

                <h4>Resultados dicotômicos</h4>
                <p>
                  Para <b>resultados dicotômicos</b>, a medida de tamanho de
                  efeito mais comumente usada para estudos em animais é a razão
                  de chances.
                </p>

                <h5>Razão de Chances</h5>
                <p>
                  <b>Razão de chances:</b>: para dados de eventos. A razão entre
                  o número de eventos e o número de não eventos. Ela representa
                  as chances de um resultado ocorrer dada uma determinada
                  exposição, em comparação com as chances de o resultado ocorrer
                  sem essa exposição.
                </p>
                <p>Tamanho do Efeito da Razão de chances:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(10).png"
                    alt="Formula"
                  />
                </div>
                <p>com o erro padrão:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(11).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  Você pode encontrar a <b>Razão de Risco</b> (ou Risco
                  Relativo), que é o risco de um evento em um grupo (por
                  exemplo, grupo exposto) versus o risco do evento em outro
                  grupo (por exemplo, grupo não exposto), ou a{" "}
                  <b>Razão de Perigo</b>; no entanto, esses dados raramente são
                  vistos em experimentos primários com animais. Para obter mais
                  informações sobre a Razão de Risco em revisões sistemáticas
                  clínicas, consulte o{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://handbook-5-1.cochrane.org/chapter_9/box_9_2_a_calculation_of_risk_ratio_rr_odds_ratio_or_and.htm"
                  >
                    Cochrane Handbook
                  </a>
                  .
                </p>

                <h5>Mediana</h5>
                <p>
                  <b>Sobrevivência mediana</b> ou dado de tempo para evento. O
                  efeito é calculado dividindo a sobrevivência mediana no grupo
                  de tratamento pela mediana no grupo controle, e o logaritmo é
                  obtido.
                </p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(12).png"
                    alt="Formula"
                  />
                </div>

                <h5>Número real dos controles</h5>
                <p>
                  Um único experimento pode conter várias comparações. Se o
                  coorte de controle estiver atendendo a mais de um grupo de
                  tratamento, corrigimos o número de animais relatados no coorte
                  de controle pelo número de grupos de tratamento.
                </p>
                <ol>
                  <li>
                    Número real dos controles
                    <p>
                      <div className="text-center">
                        <StaticImage
                          className="my-3"
                          src="../../../assets/images/wikis/pt/wiki/formulas/f(13).png"
                          alt="Formula"
                        />
                      </div>
                    </p>
                  </li>
                  <li>
                    Número real para cada comparação
                    <p>
                      <div className="text-center">
                        <StaticImage
                          className="my-3"
                          src="../../../assets/images/wikis/pt/wiki/formulas/f(14).png"
                          alt="Formula"
                        />
                      </div>
                    </p>
                  </li>
                  <li>
                    Convertendo SEM para SD
                    <p>
                      <div className="text-center">
                        <StaticImage
                          className="my-3"
                          src="../../../assets/images/wikis/pt/wiki/formulas/f(15).png"
                          alt="Formula"
                        />
                      </div>
                    </p>
                  </li>
                  <li>
                    e
                    <p>
                      <div className="text-center">
                        <StaticImage
                          className="my-3"
                          src="../../../assets/images/wikis/pt/wiki/formulas/f(16).png"
                          alt="Formula"
                        />
                      </div>
                    </p>
                  </li>
                </ol>

                <h3>10.2 Etapa 2. Combinar tamanhos do efeito</h3>
                <p>
                  O próximo passo é combinar os tamanhos do efeito para cada
                  comparação em um modelo de meta-análise.
                </p>
                <p>
                  Antes de agrupar os tamanhos do efeito, você pode querer levar
                  em consideração:
                </p>
                <p>
                  <b>Pesos dos Tamanhos do Efeito</b>: na meta-análise, é comum
                  atribuir pesos diferentes a cada estudo para refletir as
                  contribuições relativas de estudos individuais para o tamanho
                  total do efeito. Na meta-análise de estudos animais, pesamos
                  os estudos de acordo com a precisão. Estudos mais precisos
                  recebem maior peso no cálculo do tamanho do efeito.
                  Recomendamos usar o método de variância inversa, onde os
                  tamanhos de efeito individuais são multiplicados pelo inverso
                  de seu erro padrão ao quadrado:
                </p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(17).png"
                    alt="Formula"
                  />
                </div>
                <p>Onde:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(18).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  é o erro padrão ao quadrado do tamanho do efeito calculado.
                </p>
                <p>Isso fornece o peso do tamanho do efeito de:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(19).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  <b>Efeitos de aninhamento</b>: quando vários resultados são
                  relatados e é apropriado combiná-los em uma única estatística,
                  podemos “aninhar” os resultados. Para fazer isso, pegamos cada
                  resultado, calculamos o peso multiplicando pelo inverso da
                  variância desse resultado, somamos esses valores para todos os
                  resultados e dividimos pela soma dos pesos.
                </p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(20).png"
                    alt="Formula"
                  />
                </div>
                <p>Onde</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(21).png"
                    alt="Formula"
                  />
                </div>
                <p>é a medida do peso (por exemplo, variância inversa).</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(22).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  é o peso do tamanho do efeito e k denota o número total de
                  estudos incluídos na meta-análise.
                </p>
                <p>O erro padrão é calculado:</p>
                <div className="text-center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/wiki/formulas/f(23).png"
                    alt="Formula"
                  />
                </div>
                <p>
                  Existem dois modelos comumente usados para agrupar tamanhos de
                  efeito:
                </p>
                <p>
                  <ul>
                    <li>Modelo de Efeito Fixo</li>
                    <li>Modelo de Efeitos Aleatórios</li>
                  </ul>
                </p>
                <p>
                  A seleção de qual modelo usar deve ser declarada em seu
                  protocolo <i>a priori</i>. A decisão é baseada na natureza dos
                  estudos que provavelmente serão incluídos em sua revisão. O
                  modelo de efeitos aleatórios é mais comumente usado em estudos
                  pré-clínicos, pois geralmente são sintetizados dados de
                  estudos realizados em diferentes laboratórios e é esperado que
                  exista heterogeneidade. Muitas vezes sintetizamos dados de
                  experimentos onde a espécie, idade ou sexo dos animais são
                  diferentes, a intervenção pode ser dada em doses variadas ou
                  em momentos diferentes em relação ao resultado. Assumimos que
                  essas variáveis de design do estudo têm um impacto sobre os
                  efeitos que vemos nos estudos.
                </p>
                <p>
                  Raramente, ao fazer uma revisão sistemática de dados de um
                  laboratório específico, se todos os estudos em sua
                  meta-análise foram realizados usando o mesmo modelo de
                  indução, paradigmas e intervenções, você pode considerar um
                  modelo de efeito fixo. (Borenstein et al., 2009)
                </p>

                <h4>Modelo de Efeitos Fixos</h4>
                <p>
                  Sob o modelo de efeito fixo, assumimos que existe um tamanho
                  de efeito real que é compartilhado por todos os estudos
                  incluídos. Segue-se que o efeito combinado (estimativa global)
                  é nossa estimativa desse tamanho de efeito comum.
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/fixedeffects.jpeg"
                      alt="fixedeffects"
                    />
                  </div>
                </p>

                <h4>Modelo de Efeitos Aleatórios</h4>
                <p>
                  <ul>
                    <li>
                      Sob o modelo de efeitos aleatórios, permitimos que o
                      efeito real possa variar de estudo para estudo. Por
                      exemplo, o tamanho do efeito pode ser um pouco maior se os
                      pacientes forem mais velhos; em ratos vs. camundongos; se
                      o estudo usou uma variante um pouco mais intensiva ou mais
                      longa da intervenção, etc.
                    </li>
                    <li>
                      Os estudos incluídos na meta-análise são considerados uma
                      amostra aleatória da distribuição relevante de efeitos, e
                      o efeito combinado estima o efeito médio nessa
                      distribuição.
                    </li>
                  </ul>
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/randomeffects.jpeg"
                      alt="randomeffects"
                    />
                  </div>
                </p>

                <h3>10.3 Etapa 3. Investigar a Heterogeneidade</h3>
                <p>
                  A terceira etapa é investigar possíveis fontes de
                  heterogeneidade (pré-especificadas em seu protocolo). A
                  heterogeneidade é a variabilidade entre grupos de estudos
                  causada por diferenças em:
                </p>
                <p>
                  <ul>
                    <li>amostras de estudo (por exemplo, espécie, sexo)</li>
                    <li>
                      intervenções de resultados (por exemplo, dose, tipo de
                      medição de resultado)
                    </li>
                    <li>metodologia (por exemplo, design, qualidade)</li>
                  </ul>
                </p>
                <p>
                  Qui-quadrado χ<sup>2</sup> (ou Qui<sup>2</sup>) avalia se as diferenças observadas
                  nos resultados são compatíveis apenas com o acaso. I2 descreve
                  a porcentagem de variabilidade nas estimativas de efeito que
                  se deve à heterogeneidade e não ao erro de amostragem ou ao
                  acaso.
                </p>
                <p>Você pode investigar a heterogeneidade usando:</p>
                <p>
                  <ul>
                    <li>Análise de subgrupo</li>
                    <li>Modelo de meta-regressão</li>
                  </ul>
                </p>

                <h3>10.4 Etapa 4. Documentando Enviesamento</h3>
                <p>
                  O <b>viés de publicação</b> ocorre quando os resultados de
                  estudos publicados e não publicados diferem sistematicamente.
                  Infelizmente, estudos neutros e negativos demoram mais para
                  serem publicados, permanecem sem serem publicados, têm menor
                  probabilidade de serem identificados em revisão sistemática, e
                  isso pode levar a uma superestimação da eficácia na
                  meta-análise.
                </p>
                <p>
                  Há também outros vieses que podem afetar sua revisão
                  sistemática, incluindo <b>relato de resultados</b> e{" "}
                  <b>análises selecionadas</b>.{" "}
                </p>
                <p>
                  Podemos testar um possível viés de publicação em nossos dados
                  colocando-os em um <b>gráfico de funil</b>. As linhas
                  tracejadas externas indicam a região triangular na qual se
                  espera que 95% dos estudos se encontrem, na ausência de vieses
                  e heterogeneidade. A linha vertical sólida refere-se à linha
                  sem efeito. Imagem adaptada de{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.bmj.com/content/343/bmj.d4002"
                  >
                    Sterne et al., 2011
                  </a>
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/funnelplot.jpeg"
                      alt="funnelplot"
                    />
                  </div>
                </p>
                <p>
                  Se você observar assimetria em seu gráfico de funil, pode
                  haver várias razões:
                </p>
                <p>
                  <ul>
                    <li>
                      Documentações Enviesadas
                      <ul>
                        <li>Viés de Publicação</li>
                        <li>Relatório seletivo dos resultados</li>
                        <li>Relatório seletivo das análises</li>
                      </ul>
                    </li>
                    <li>
                      Má qualidade metodológica (levando a efeitos inflados em
                      estudos menores)
                      <ul>
                        <li>Mau desenvolvimento metodológico</li>
                        <li>Análise inadequada</li>
                        <li>Fraude</li>
                      </ul>
                    </li>
                    <li>
                      Heterogeneidade real: o tamanho do efeito difere de acordo
                      com o tamanho do estudo devido, por exemplo, às diferenças
                      na intensidade das intervenções ou no risco subjacente
                      entre estudos com tamanhos diferentes.
                    </li>
                    <li>
                      Artefatos: a variação amostral pode levar a uma associação
                      entre o efeito da intervenção e seu erro padrão.
                    </li>
                    <li>
                      Acaso: a assimetria pode ocorrer por acaso - motivando o
                      uso de testes estatísticos de assimetria.
                    </li>
                  </ul>
                </p>

                <h3>10.5 Etapa 5. Interpretando os Resultados</h3>
                <p>
                  O gráfico de floresta (forest plot) ou timber plot é a
                  principal representação gráfica de uma meta-análise.
                </p>
                <p>
                  Ler e entender esses gráficos permitirá que você entenda os
                  resultados de uma meta-análise. Meta-análises de estudos em
                  animais tendem a incluir muitos estudos com amostras pequenas.
                  Portanto, é comum ver meta-análises pré-clínicas representadas
                  graficamente com um timber plot, uma pequena variação do
                  gráfico de floresta.
                </p>
                <p>
                  Aqui está um exemplo de um timber plot. Nesta meta-análise a
                  pergunta de pesquisa foi: Qual é o efeito dos antidepressivos
                  comparados com o veículo ou com o não tratamento no volume do
                  infarto em modelos animais de acidente vascular cerebral
                  isquêmico? Adaptado de{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://doi.org/10.1161/STROKEAHA.114.006304"
                  >
                    McCann et al., 2014
                  </a>
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/timberplot.jpeg"
                      alt="timberplot"
                    />
                  </div>
                </p>
                <p>
                  Resultado: uma meta-análise é conduzida com base em um único
                  resultado de interesse de cada vez. O resultado de interesse
                  nesta meta-análise é a redução do volume do infarto, conforme
                  exibido no rótulo do eixo y.
                </p>
                <p>
                  Efeitos individuais do estudo: nesta meta-análise foram
                  incluídos 58 experimentos. Cada ponto preto representa o
                  tamanho do efeito relatado em um único experimento, a
                  diferença no resultado entre a média e o grupo de controle.
                  Cada ponto preto tem linhas pretas finas acima e abaixo do
                  tamanho do efeito, que representam as margens de erros
                  associadas ao tamanho do efeito relatado. Os tamanhos de
                  efeitos individuais de estudo são exibidos em ordem do menor
                  para o maior para destacar a variação ou heterogeneidade na
                  literatura.
                </p>
                <p>
                  Efeito agrupado: aqui, a barra cinza atrás dos pontos pretos
                  representa o tamanho do efeito combinado ou agrupado em todos
                  os experimentos incluídos e seus intervalos de confiança (IC).
                  Neste exemplo, o tamanho do efeito é 27,3% (IC 95%,
                  20,7%–33,8%).
                </p>
                <p>
                  <b>Gráfico de floresta para meta-análise clínica</b>: Um guia
                  passo a passo para interpretar um gráfico de floresta a partir
                  de uma meta-análise clínica típica está disponível{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://s4be.cochrane.org/blog/2016/07/11/tutorial-read-forest-plot/"
                  >
                    aqui
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#110">
                <h3 className="fw-bold">11 Ferramentas para Revisão Sistemática</h3>
                <p>
                  É altamente recomendável usar softwares e ferramentas para
                  ajudá-lo ao longo do caminho. Mencionamos muitas ferramentas
                  ao longo deste Wiki, aqui está uma lista de todas elas:
                </p>
                <p>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://syrf.org.uk/"
                  >
                    SyRF
                  </a>{" "}
                  - Systematic Review Facility é uma plataforma online de uso
                  gratuito para auxiliar na sua revisão sistemática pré-clínica.
                  Seus recursos e ferramentas auxiliares incluem:
                </p>
                <p>
                  <ul>
                    <li>
                      Atualização automática da sua busca no{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://pubmed.ncbi.nlm.nih.gov"
                      >
                        PubMed
                      </a>
                    </li>
                    <li>
                      Deduplicação de buscas sistemáticas no{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://camarades.shinyapps.io/RDedup"
                      >
                        AsysD App
                      </a>
                    </li>
                    <li>Triagem (título e resumo, bem como texto completo)</li>
                    <li>Anotação e extração de dados</li>
                    <li>
                      Meta-análise de dados do SyRF{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://camarades.shinyapps.io/meta-analysis-app/"
                      >
                        clique aqui
                      </a>
                    </li>
                  </ul>
                </p>
                <p>As ferramentas adicionais incluem:</p>
                <p>
                  <ul>
                    <li>
                      Triagem de citações:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://rayyan.qcri.org/welcome"
                      >
                        Rayyan
                      </a>{" "}
                      e{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://sysrev.com"
                      >
                        SysRev
                      </a>
                      .
                    </li>
                    <li>
                      Extração de dados de gráficos:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://helpx.adobe.com/acrobat/using/grids-guides-measurements-pdfs.html"
                      >
                        Adobe desktop ruler
                      </a>{" "}
                      ou WebPlotDigitizer
                    </li>
                    <li>
                      Tradução de pesquisa em bases de dados:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://sr-accelerator.com/#/polyglot"
                      >
                        Polyglot Search Translator
                      </a>
                    </li>
                  </ul>
                </p>
                <p>
                  Machine Learning - se você estiver fazendo uma grande revisão
                  sistemática, considere aprender ML. Entre em contato conosco
                  para mais informações.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#120">
                <h3 className="fw-bold">12 Publicação</h3>
                <p>
                  Você realizou sua revisão sistemática e, potencialmente,
                  também conduziu uma meta-análise, agora é hora de contar à
                  comunidade o que você encontrou e garantir que as descobertas
                  da revisão cheguem ao público.
                </p>
                <p>
                  Tenha cuidado ao interpretar os resultados; reconheça fontes
                  de enviesamento; leve em consideração a heterogeneidade,
                  generalização e relevância.
                </p>
                <p>
                  Pode ser útil usar uma{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0187271"
                  >
                    abordagem GRADE
                  </a>{" "}
                  para avaliar a certeza da evidência de estudos pré-clínicos em
                  animais, no contexto de intervenções terapêuticas.
                </p>
                <p>
                  Apresente sua revisão sistemática de forma que permita a
                  reprodutibilidade dos resultados e atualização futura.
                </p>

                <h4>12.1 Produzindo um bom relatório</h4>
                <p>
                  Recomendamos seguir as Diretrizes de Itens de Documentação
                  Preferenciais para Revisões Sistemáticas e Meta-análises
                  (Preferred Reporting Items for Systematic Reviews and
                  Meta-analyses - PRISMA).
                </p>
                <p>
                  Um checklist de verificação pode ser encontrado{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.prisma-statement.org/PRISMAStatement/Checklist"
                  >
                    aqui
                  </a>
                </p>
                <p>
                  Recomendamos usar o fluxograma PRISMA para visualizar os
                  estudos em seu processo de revisão sistemática. O modelo de
                  fluxograma PRISMA pode ser{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://prisma-statement.org/prismastatement/flowdiagram"
                  >
                    encontrado aqui
                  </a>
                  .
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/PRISMA.png"
                      alt="PRISMA"
                    />
                  </div>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#130">
                <h3 className="fw-bold">13 Recursos e links</h3>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://syrf.org.uk"
                  >
                    SyRF
                  </a>
                </p>
                <p>
                  <Link to="/guia-syrf">Guia de ajuda do SyRF</Link>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://onlinelibrary.wiley.com/doi/epdf/10.1002/ebm2.7"
                  >
                    Protocolo SYRCLE - Template & Paper
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0187271"
                  >
                    Hooijmans et al., 2018. Abordagem pré-clínica GRADE
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://doi.org/10.1016/j.jneumeth.2013.09.010"
                  >
                    Vesterinen et al, 2014. “Meta-analysis of data from animal
                    studies: a practical guide.” Journal of neuroscience methods
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://onlinelibrary.wiley.com/doi/book/10.1002/9780470743386"
                  >
                    Borenstein et al., 2009. Introduction to Meta-Analysis
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://handbook-5-1.cochrane.org"
                  >
                    Cochrane Handbook
                  </a>
                </p>

              </Tab.Pane>
              <Tab.Pane eventKey="#140">
                <h3 className="fw-bold">14. Sobre</h3>
                <p>
                  Criamos esta página Wiki para fornecer informações e
                  documentos, links e ferramentas úteis para guiá-lo através de
                  sua revisão sistemática pré-clínica. Esses recursos foram
                  reunidos usando muitas fontes CC-BY-4.0, incluindo:{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://syrf.org.uk/"
                  >
                    SyRF
                  </a>{" "}
                  e{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://training.cochrane.org/interactivelearning"
                  >
                    Cochrane Interactive Learning
                  </a>
                  . Agradecemos a essas organizações e equipes por
                  disponibilizarem seus recursos, que você também deve visitar!
                </p>
                <p>
                  Este recurso foi atualizado pela última vez em: 15 dezembro,
                  2021
                </p>

                <h3>14.1 Como citar a ferramenta?</h3>
                <p className="font-monospace">
                  CAMARADES Berlin. WIKI de Revisão Sistemática Pré-clínica e
                  Meta-Análise. Tradução por CAMARADES Brasil. Outubro, 2021.{" "}
                  {getPTCitation()}{" "}
                </p>

                <h3>14.2 Equipe do conteúdo original</h3>
                <p>
                  <ul>
                    <li className="fw-bold">Sarah McCann, PhD</li>
                    <li>Torsten Rackoll, PhD</li>
                    <li>Alexandra Bannach-Brown, PhD</li>
                    <li>Florenz Cruz</li>
                  </ul>
                </p>

                <h3>14.3 Equipe de tradução</h3>
                <p>
                  <ul>
                    <li>Tamires Martins, Ms</li>
                    <li>Juliana Aparecida Bolzan, Ms </li>
                    <li>Cilene Lino de Oliveira, PhD</li>
                  </ul>
                </p>
                <p>
                  Se você tiver dúvidas sobre os recursos ou gostaria de fazer
                  uma pergunta sobre sua revisão especificamente, entre em
                  contato:{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="mailto:camaradesbr@gmail.com"
                  >
                    envie-nos um e-mail aqui
                  </a>
                </p>
                <p>
                  O recurso é apoiado por Charité 3Rs. Para obter mais
                  informações sobre os 3Rs na Charité – Universitätsmedizin
                  Berlin, visite o{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://charite3r.charite.de/en/charite_3r_toolbox/"
                  >
                    Charité 3Rs Toolbox
                  </a>
                  .
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/wiki/charite3rs_logo.jpeg"
                      alt="charite3rs_logo"
                    />
                  </div>
                </p>
                <p>
                  A tradução do conteúdo deste recurso do inglês para o
                  português foi apoiada pela Fundação Alexander von Humboldt.
                  Para mais informações visito o{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.humboldt-foundation.de/"
                  >
                    site
                  </a>
                  .{" "}
                </p>
                <p>
                  <div className="text-center">
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/sponsors/avh.jpeg"
                      alt="alexander_logo"
                    />
                  </div>
                </p>
                <p>
                  CAMARADES Berlin está localizado no QUEST Center, Berlin
                  Institute for Health{" "}
                </p>
                <p>
                  CAMARADES BR está localizado no Laboratório de Neurobiologia
                  do Comportamento (LabNeC-CFS-CCB) da Universidade Federal de
                  Santa Catarina (UFSC).
                </p>

                <Link to="/sobre-a-camarades#apoiadores">
                  <h3>14.3 Demais apoiadores</h3>
                </Link>

                <h3>14.4 Contato</h3>
                <p>
                  Se você tiver dúvidas sobre os recursos, quiser fazer uma
                  pergunta específica ou contribuir para o texto,{" "}
                  <Link to="/contato">entre em contato conosco aqui</Link>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={1}></Col>
        </Row>
      </Tab.Container>
    </section>
  )
}
